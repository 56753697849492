import { render, staticRenderFns } from "./DesignEditor.vue?vue&type=template&id=6fe6f49e&scoped=true&"
import script from "./DesignEditor.vue?vue&type=script&lang=js&"
export * from "./DesignEditor.vue?vue&type=script&lang=js&"
import style0 from "./DesignEditor.vue?vue&type=style&index=0&id=6fe6f49e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe6f49e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
installComponents(component, {VColorPicker})
