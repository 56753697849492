


<template>
<div class="content-block-social" :style="linkStyle">
  <div data-v-10ab4930="" class="icon Cell"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :style="iconStyle"><path fill-rule="nonzero" d="M22.68 1.32C21.8.44 20.74 0 19.5 0h-15C3.26 0 2.2.44 1.32 1.32.44 2.2 0 3.26 0 4.5v15c0 1.24.44 2.3 1.32 3.18C2.2 23.56 3.26 24 4.5 24h15c1.24 0 2.3-.44 3.18-1.32.88-.88 1.32-1.94 1.32-3.18v-15c0-1.24-.44-2.3-1.32-3.18zM20 12.5c0 .437-.203.744-.61.922a1.088 1.088 0 0 1-.39.078.93.93 0 0 1-.703-.297l-2.25-2.25-8.343 8.344a.962.962 0 0 1-.704.297.962.962 0 0 1-.703-.297l-1.594-1.594A.96.96 0 0 1 4.406 17a.96.96 0 0 1 .297-.703l8.344-8.344-2.25-2.25c-.323-.302-.396-.666-.219-1.094.177-.406.485-.61.922-.61H19c.27 0 .505.1.703.298A.961.961 0 0 1 20 5v7.5z"></path></svg></div>
  <a data-v-10ab4930="" @click.prevent="showLink" :href="block.details.url" class="social-label font-bold" >{{ block.details.text?block.details.text:block.details.type }}</a>
  <div data-v-10ab4930="" class="cheveron"><svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg"><path d="M2.13659 11.6702L7.948 5.9999L2.13659 0.32959L0.48291 1.68541L4.90476 5.9999L0.48291 10.3144L2.13659 11.6702Z"></path></svg></div>

</div>
</template>

<script>
export default {
    name: "content-block-social",
    props: {
      block: {
        type: Object,
        required: true
      },
      settings: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      showLink: function(){
          this.$emit("overlay",this.block.details.url)
      }
    },
    computed: {
      linkStyle: function(){
        if(this.settings.design.color){
          return {
            color: this.settings.design.color
          }
        }

        return {}

      },

      iconStyle: function(){
        if(this.settings.design.color){
          return {
            fill: this.settings.design.color
          }
        }

        return {}

      }
    }
}
</script>

<style scoped>


.content-block-social {
  padding: 14px 0;
  margin: 10px 15px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  border-color: rgba(0,0,0,.2) currentcolor;
  border-top: 1px solid rgba(0,0,0,.2);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
  box-sizing: border-box;
}



.social-label {
  font-size: 1.4em;
  padding: 0 5px;
  text-decoration: none;
  flex-grow: 1;
  font-weight: bold;
  color: currentColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cheveron {
  width: 1.5em;
  height: 1.5em;
  margin-right: 10px;
  padding: 0 5px;
}

.cheveron svg {
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
}

</style>
