import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import QRCode from 'qrcode'

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

Vue.use(Vuex)

let reviewer = localStorage.getItem("reviewer");
//let sessionId = localStorage.getItem("sessionId");
//let loaded = sessionId?false:true;
//let loaded = false;
let authorized = false;





export default new Vuex.Store({
  state: {
    authorized: authorized,
    sessionId: 0,
    reviewer: reviewer,
    loaded: false,
    unsavedChanges: false,
    saving: false,
    demo: {

      lastTrigger: null,
      checklist: false,
      task: -1,
      step: 0,
      tasks: [
        {
          title: "Enter Your Product Info",
          description: "Specify a name, image, and sku",
          steps: 3,
          completed: false
        },
        {
          title: "Build Your Landing Page",
          description: "Add links, photos, and buttons",
          steps: 4,
          completed: false
        },
        {
          title: "Generate Custom QR Code",
          description: "Preview on your mobile device",
          steps: 2,
          completed: false
        }
      ]
    },
    settings: {
      reviewer: "",
      demosession: "",
      verified: false,
      dashboard: {
        scans: 0,
        codes: 0,
        users: 0,
        products: 0,
      },
      design: {
        color: "#2095F2FF"
      },
      campaign: {
        name: "ePac Connect"
      },
      pageTitle: "ePac Connect",
      product: {
        name: "ChocoMania!",
        sku: "-2012-001",
        url: "https://doisyanddam.com/products/pink-yellow",
        image: "https://cc.scantrust.com/c/459/product/blob-asyfxa.png"
      },
      redirect: {
        type: "custom", //custom, static, product
        url: ""
      },
      header: {
        picture: {
          display: "contain"
        },
        scanResponse: {
          text: "This code is verified",
          poweredByScanTrust: true
        }
      },
      body: [

      ]
    },
  },
  getters: {
    currentTask: function(state){
      if(state.demo.task > -1){
        return state.demo.tasks[state.demo.task];
      }
    }
  },
  mutations: {
    authorize: function (state){
      state.authorized = true;
      localStorage.setItem("sessionId", Date.now());
    }
  },
  actions: {

    logout: function(context){

      context.state.authorized = false;
    },

    dismissTask: function(context){
      context.state.demo.task = -1;
    },

    setTask: function(context,taskIndex){

      let step = 1;
      let task = -1;
      if(taskIndex){
        step = taskIndex.step;
        task = taskIndex.task;
      }
      console.log(taskIndex, step);

      context.state.demo.step = step;
      context.state.demo.task = task;

      context.state.demo.checklist = false;
    },

    triggerStep: function(context, index){
      if(index && (context.state.demo.task === index.task)){
        context.state.demo.step = index.step;
        if(index.step > context.getters.currentTask.steps){
          context.getters.currentTask.completed = true;
        }
        context.state.demo.lastTrigger = index;
      }
    },

    completeStep: function(context, index){


      if(context.getters.currentTask){

        let tgtStep = context.state.demo.step + 1;

        if(index) {
          if(!(index.task === context.state.demo.task) || !(index.step === context.state.demo.step)){

            console.log("not correct step");
            return;
          }
        }




        if((tgtStep) <= context.getters.currentTask.steps){
          context.state.demo.step = tgtStep;
        }else{
          context.state.demo.step = 0;
          context.state.demo.task.completed = true;
          context.state.demo.task = -1;
          context.state.demo.checklist = true;
        }
      }
    },

    submitPreview: async function(context, qrCodeURL){

      if(!qrCodeURL){ return }
      console.log("Submit Preview");

      const url = "/api/submitPreview"

      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {
                reviewer: context.state.settings.reviewer,
                codeURL: qrCodeURL,
                session: context.state.settings.id
            }
        )
      }

      return fetch(url,options)
          .then(response => response.json())
          .then((response) => {
            return response;
          })

    },

    saveQRCode: async function(context, recordId){




        if(!recordId){ return }




        //const filename = encodeURIComponent(file.name);
        //const fileType = "image/png"

        //let host = window.location.host;
        let previewURL = `https://connectdemo.epacflexibles.com/#/preview?settings=${context.state.settings.id}`

        let qrData = await QRCode.toDataURL(previewURL, { width: 330, margin: 0, version:10, errorCorrectionLevel: 'H' }).then((fileData) => {
          return fileData;
        })

        console.log(qrData);

        let file = dataURLtoFile(qrData,"qrcode.png");


      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {key:recordId+".png" }
        )
      }

      let res = await fetch("/api/generatePreview",options)
          .then(response => response.json())


        //const res = await fetch(`/api/upload_put?type=${fileType}`);
        console.log(res);
        //const { url, key } = await res.json();

        //console.log(url, key);
        let putConfig = {
          headers: {
            'x-amz-acl':'public-read',
            'Content-Type': file.type
          }
        }

        const upload = await axios.put(res.url, file, putConfig ).catch((error)=>{
          console.log('error',error)
        })

        console.log(upload);


        if (upload.status == 200) {
          console.log('Uploaded successfully!', upload);
          return res.url.split('?')[0];
        } else {
          console.error('Upload failed.');
          return false;
        }


    },

    verifyCode: function(context, payload){

      console.log("verfying code");

      if(payload) {
        console.log("Calling api");
        const url = "/api/verifyUser"
        const options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify(
              payload
          )
        }

        return fetch(url, options)
            .then(response => response.json())
            .catch((error) => {
              console.log(error);
              return false;
            })

      }else{
        console.log("No payload present");
        return false;
      }
    },


    initVerification: function(context, id){

      let reviewer = context.state.reviewer;

      if(id){
        reviewer = id;
      }

      if(reviewer) {

        console.log("Load", reviewer, context.state.settings.id);

        const url = "/api/initVerification"
        const options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify(
              {reviewer: reviewer, refId: context.state.settings.id}
          )
        }

        return fetch(url, options)
            .then(response => response.json())
            .catch((error) => {
              console.log(error);
              return false;
            })

      }else{
        console.log("No reviewer id present");
        return false;
      }

    },

    initUser: async function(context, settings){

      console.log("Create",settings);
      settings.verified = false;
      const url = "/api/initSession"

      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {settings}
        )
      }

      return fetch(url,options)
          .then(response => response.json())
          .then((response) => {
            let sessionId = Date.now();
            //localStorage.setItem("sessionId",sessionId);
            context.state.sessionId = sessionId;
            return response;
          })

    },
    updateSession: function(context, settings){

      console.log("Update",settings);

      const url = "/api/update"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {settings, reviewer:context.state.reviewer }
        )
      }

      return fetch(url,options)
          .then(response => response.json())

    },
    loadPreview: function(context, id){

      if(!id){
        return;
      }

        console.log("Load", id);

        const url = "/api/userSession"
        const options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify(
              {id: id}
          )
        }

        return fetch(url, options)
            .then(response => response.json())
            .then((record) => {
              console.log(record);
              return record;
            })
            .catch((error) => {
              console.log(error);
              //context.state.loaded = true;
              return false;
            })


    },
    loadSession: function(context, id){

      let reviewer = context.state.reviewer;

      if(id){
        reviewer = id;
      }

      if(reviewer) {

        console.log("Load", reviewer);

        const url = "/api/userSession"
        const options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify(
              {reviewer: reviewer}
          )
        }

        return fetch(url, options)
            .then(response => response.json())
            .then((records) => {
              console.log(records);

              if(records.data.length){
                let record = records.data[0];
                let settings = record.data;
                settings.id = record.ref["@ref"].id;

                context.state.settings = settings;
                context.state.reviewer = reviewer;

                let sessionId = record.ts / 1000;
                localStorage.setItem("reviewer", reviewer)
                context.state.sessionId = sessionId;
                context.state.loaded = true;
                console.log(settings);


                return settings;
              }else{
                context.state.loaded = true;
                return false;
              }



              // if (settings.ref) {
              //   context.state.settings = settings.data;
              // }


            })
            .catch((error) => {
              console.log(error);
              //context.state.loaded = true;
              return false;
            })

      }else{
        console.log("No reviewer id present");
        //context.state.loaded = true;
        return false;
      }

    },
    saveSettings: async function(context){

      if(!context.state.reviewer){
        context.registered = false;
        return;
      }



        if(context.state.unsavedChanges){
          console.log("saving changes");
          context.state.saving = true;
          context.dispatch("updateSession", context.state.settings).then((session) => {
            console.log("success",session);
            context.state.unsavedChanges = false;
            context.state.saving = false;
          })
              .catch((error) => {
                console.log(error);
                context.state.saving = false;
              })
          }
        //}

    }

  },
  modules: {
  }
})
