<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light black--text">
    <path d="M11 16V8H8c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1h10c.3 0 .55.1.725.275C17.9 6.45 18 6.7 18 7v1c0 .6-.4 1-1 1s-1-.4-1-1h-3v8c.6 0 1 .4 1 1s-.4 1-1 1h-2c-.6 0-1-.4-1-1s.4-1 1-1zm9 8H4c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4h16c2.2 0 4 1.8 4 4v16c0 2.2-1.8 4-4 4zM4 2c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-text",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.icon-text {

}

</style>
