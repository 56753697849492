


<template>
<div class="landing-page">
  <div class="tabs-ctn">
    <div class="tabs">

      <tab-nav class="" :tabs="tabs" :selection="currentTabIndex" @select="onTabSelect"></tab-nav>



    </div>
    <div class="actions-ctn">
      <div class="segmented-button-ctn">
        <button @click="saveSettings" type="button" class="font-weight-black v-btn v-btn--block v-btn--contained theme--light v-size--default primary">
          <span class="v-btn__content">
            <span>{{ statusMsg }}</span>
          </span></button>



        <div class="hint sidebar-hint font-weight-bold show">All changes saved but not yet synced to the published version.</div>
        <div class="hint sidebar-hint font-weight-bold"><span>Your redirection changes are not updated yet.</span></div></div></div>
  </div>


  <div class="editor">

    <component :is="currentTab.component" :settings="settings"></component>


  </div>

  <div class="mobile-preview">
      <div class="preview-label pt-3 pb-5 font-weight-bold">Live Preview:</div>
    <div class="relative">
      <div class="mobile-button mb-2">
        <div class="qrcode-button" :data-blink="highlightQRCodeButton">
        <button @click="showMobileCode" type="button" class="font-weight-bold mt-1 v-btn v-btn--flat v-btn--left v-btn--text theme--light v-size--default primary--text" >
        <span class="v-btn__content">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light"><path fill="#15bbf0" fill-rule="evenodd" d="M19 19H5V5h7V3H5c-1.105 0-2 .895-2 2v14c0 1.105.895 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>
          <span class="ml-1">Get My QR Code</span>

      </span>

        </button>
        </div>

        <v-dialog
            v-model="mobilePreview"
            persistent
            max-width="700px"
        >
          <v-card>
            <v-card-title class="px-8" style="background-color:#f1f1f1;">{{ mobilePreviewTitle }}</v-card-title>
            <v-window v-model="mobilePreviewScreen">
              <v-window-item :value="1">
                <v-card-text>

                  <v-container>
                    <p>To complete this demo, click <b>Send My QR Code</b> below. An email will be sent to {{ settings.reviewer }} with a QR Code that you can use to preview the mobile interface you just built.</p>
                    <p>If you’d like to make changes, come back and the same, secure, QR code will lead to your updated experience.</p>
                  </v-container>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text>
                  <v-container>
                    <p>An email has been sent to {{ settings.reviewer }} with a QR Code that you can now use to preview the mobile interface you just built.</p>
                    <p>If you’d like to make changes, come back and the same, secure, QR code will lead to your updated experience.</p>
                    <p>To learn more, check our our technology <a href="https://epacflexibles.com/" target="_blank">explainer video here</a>.</p>
                    <p>To speak with a ePac Smart Packaging specialist, <a target="_blank" href="https://epacflexibles.com/contact/">click here</a>.</p>
                    <p>Thanks and best wishes from your team at ePac Flexible Packaging</p>



                  </v-container>
                </v-card-text>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  plain
                  @click="closeMobilePreview"
              >
                Return to Demo
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="sendQRCode"
              >
                {{ sendCodeTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>
      </div>
      <tutorial-dialog :task="2" :step="1" position="left" align="top" style="margin-right: -42px;">When your landing page is finished, click here to generate a secure QR Code to preview your custom page on a mobile device.</tutorial-dialog>

    </div>
      <div class="phone-frame" style="">
        <img class="" src="/img/iphone-frame.png" />
        <div class="phone-header font-weight-bold">{{ settings.pageTitle }}</div>
        <content-preview class="phone-content" :settings="settings" :preview-mode="true" v-if="loaded"></content-preview>

      </div>




  </div>

</div>
</template>

<script>
import TabNav from "@/components/TabNav";
import RedirectEditor from "@/components/RedirectEditor";
import DesignEditor from "@/components/DesignEditor";
import LayoutEditorD from "@/components/LayoutEditorD";
import GlobalSettingsEditor from "@/components/GlobalSettingsEditor";
import ContentPreview from "@/components/ContentPreview";
//import QRCode from 'qrcode'
import TutorialDialog from "@/components/TutorialDialog";

export default {
    name: "landing-page",
  components: {TutorialDialog, ContentPreview, GlobalSettingsEditor, LayoutEditorD, DesignEditor, RedirectEditor, TabNav},
  props: {},
  mounted() {
    // if(!this.$store.state.loaded){
    //   this.$store.dispatch("loadSession");
    // }
    if(this.$route.query.tab !== 'editor'){
      this.$store.dispatch("triggerStep", { task:1, step: 2});
    }else{
      this.$store.dispatch("triggerStep", { task:1, step: 3});
    }

  },

  watch: {

  },

  data: function(){
        return {
          checklist: false,
          mobilePreview: false,
          mobilePreviewScreen: 1,
          tabs: [
            {
              label: "QR Redirect To:",
              id:"redirects",
              description: "Landing Page",
              component: "RedirectEditor"
            },
            {
              label: "Landing Page Editor",
              id:"editor",
              description: "Customize with brand, product information, etc.",
              component: "LayoutEditorD"
            },
            {
              label: "Design",
              id:"design",
              description: "Colors",
              component: "DesignEditor"
            },
            {
              label: "Global Settings",
              id:"settings",
              description: "Languages & others.",
              component: "GlobalSettingsEditor"
            },
          ]
        }
        },
    methods: {

      closeMobilePreview: function(){
        console.log("close preview");
        this.mobilePreview = false;
      },

      sendQRCode: async function(){
        if(this.mobilePreviewScreen === 1){

          let qrCodeURL = await this.$store.dispatch("saveQRCode", this.$store.state.settings.id);

          if(qrCodeURL){

            let submission = await this.$store.dispatch("submitPreview", qrCodeURL);

            if(submission && submission.success){
              this.mobilePreviewScreen = 2;
              this.$gtag.event("qrcode-request", {
                'event_category': "engagement",
                'event_label': "method"
              })
            }else{
              alert("Something went wrong. Try again later.")
            }
          }


        }else{
          this.mobilePreviewScreen = 1;
        }

      },

      saveSettings: async function(){
        this.$store.dispatch("saveSettings");

      },



      onTabSelect: function(index){
        this.$router.push({
          query: {
            tab: this.tabs[index].id
          }
        })
        this.$store.dispatch("saveSettings");
      },
      showMobileCode: function(){

        console.log(this.$refs);

        if(!this.$store.state.sessionId){
          alert("You have not published your page yet. Click update to save this landing page.");
          return;
        }

        this.$store.dispatch("saveSettings");
        //let host = window.location.host;
        //let url = `${window.location.protocol}//${host}/#/preview?settings=${this.$store.state.sessionId}`
        //let url = `http://192.168.4.126:3000/#/preview?settings=${this.$store.state.sessionId}`


        this.$store.dispatch("triggerStep",{task: 2, step: 3})
        this.mobilePreviewScreen = 1;
        this.mobilePreview = true;

        this.$gtag.event("qrcode-dialog", {
          'event_category': "engagement",
          'event_label': "method"
        })


        // this.$nextTick(()=>{
        //   QRCode.toCanvas(this.$refs.qrcode, url,{ width: 400, margin: 0, version:6, errorCorrectionLevel: 'H' }, (error)=>{
        //     if (error) console.error(error)
        //     console.log('QRCode is complete');
        //   })
        // })
      }
    },
    computed: {

      highlightQRCodeButton: function(){
        if(this.settings.body.length){
          return true;
        }

        return false;
      },

      mobilePreviewTitle: function(){
        if(this.mobilePreviewScreen === 2){
          return "QR Code Activated"
        }else{
          return "Preview on Mobile"
        }
      },

      sendCodeTitle: function(){
        if(this.mobilePreviewScreen === 2){
          return "Try Sending QR Code Again"
        }else{
          return "Send My QR Code"
        }
      },

      currentTab: function(){

        let tgtIndex = 0;

        if(this.$route.query.tab){
          tgtIndex = this.tabs.findIndex((tab) => {
            return (tab.id === this.$route.query.tab)
          })
        }
        return this.tabs[tgtIndex]
      },

      currentTabIndex: function(){

        if(this.currentTab){
          let tgtIndex = this.tabs.findIndex((tab) => {
            return (tab.id === this.currentTab.id)
          })

          return tgtIndex;
        }

        return 0

      },

      settings: function(){
        return this.$store.state.settings;
      },
      loaded: function(){
        return this.$store.state.loaded;
      },
      statusMsg: function(){
        if(this.$store.state.saving){
          return "Saving"
        }else{
          return "Update"
        }

      }
    }
}
</script>

<style scoped>


.landing-page {
  display: flex;
  height: calc(100% - 64px);
  position: relative;
}

.tabs-ctn {
  width: 240px;
  flex-grow: 0;
  flex-shrink: 0;

  position: relative;
  padding-top: 54px;
  box-sizing: border-box;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tabs {

  padding-left: 20px;
  width: 100%;

}

.tab-nav {

}

.editor {
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.mobile-preview {
  width: 300px;
  border-left: 1px solid rgba(0,0,0,.3);
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 20;
  display: none;
}

.preview-label {
  font-family: 'Roboto Mono', monospace;
  text-align: center;
  opacity: 0.5;
  font-size: 20px;
}


.phone-frame {
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  margin-top: 7px;
  position: relative;
  overflow: hidden;
}

.phone-frame img {
  width: 100%;
  z-index: 10;
  position: relative;
  pointer-events: none;
}

.phone-header {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 27px;
  padding-bottom: 8px;
  background: #f0eff5;
  border-top: 10px solid #f0eff5;
}

.phone-content {
  position: absolute;
  width: 100%;
  height: calc(100% - 87px);
  bottom: 0;
  left: 0;
  padding: 0 14px;
  right: 0;
  top: 67px;
  box-sizing: border-box;
  overflow-y: scroll;
  background: white;

}

.phone-content::-webkit-scrollbar {
  display: none;
}

.mobile-button {
  text-align: center;
}

.qrcode {
  width: 400px;
  height: 400px;
}

.actions-ctn {
  width: 100%;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.segmented-button-ctn {
  position: relative;
}

.actions-ctn button {
  margin-top: 15px;
}

.actions-ctn .v-btn {
  color: #333!important;
}

.actions-ctn .drop-down-btn.v-btn {
  background-color: #333!important;
  max-width: 40px;
  width: 40px;
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
}



.hint {
  text-align: center;
  padding: 10px;
  color: #f27f2e;
  font-size: 12px;
}

.sidebar-hint {
  opacity: 0;
}

.sidebar-hint.show {
  opacity: 1.0;
}

@keyframes blink {
  0% { opacity: 0.1; }
  50% { opacity: 0; }
  100% { opacity: 0.1; }
}

.qrcode-button {

}

.qrcode-button[data-blink="true"] .v-btn::before {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

}

.qrcode-button[data-blink="true"] .v-btn:hover::before {
  animation: none;
  opacity: 0.15;

}

@media (min-width: 1050px){
  .mobile-preview {
    display: block;
  }
}





@media (min-width: 1500px){
  .mobile-preview {
    width: 420px;

  }

  .phone-frame {
     border-radius: 48px;
    width: 320px;
    margin-top: 15px;

  }

  .phone-header {
    padding-top: 41px;
  }

  .product-image {
    height: 275px;
  }

}



</style>
