


<template>
<div class="content-block-text">
  <p>{{ block.details.text }}</p>
</div>
</template>

<script>
export default {
    name: "content-block-text",
    props: {
      block: {
        type: Object,
        required: true
      },
      settings: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.content-block-text {
  margin: 10px 14px;
  font-size: 11px;
  font-size: 1em;
}

</style>
