


<template>

  <div class="tutorial-dialog black white--text" :class="position" :data-active="isActive" :data-align="align" :style="computedStyles">
    <slot></slot>
    <svg class="carat" xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" aria-hidden="true" ><path fill="#000" d="M0 12 L12 0 24 12z"></path></svg>
    <div class="tutorial-dialog__actions">
      <div class="ok font-weight-bold" @click="dismiss">CANCEL TOUR</div>
    </div>
  </div>


</template>

<script>
export default {
    name: "tutorial-dialog",
    props: {
      position: {
        type: String,
        default: "top"
      },
      align: {
        type: String,
        default: "middle"
      },
      task: {
        type: Number,
        default: -1
      },
      step: {
        type: Number,
        default: -1
      },
      maxWidth: {
        type: String,
        default: "200px"
      }
    },
    data: function(){
        return {}
        },
    methods: {
      dismiss: function(){
        this.$store.dispatch("dismissTask");
      }
    },
    computed: {
      isActive: function(){
        return ((this.$store.state.demo.task === this.task) && (this.$store.state.demo.step === this.step))
      },

      computedStyles: function(){
        return {
          width: this.maxWidth
        }
      }
    }
}
</script>

<style scoped>


.tutorial-dialog {
  background-color: white;
  border: 1px solid #888;
  position: absolute;
  padding: 12px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 56px 0 rgba(0, 0, 0, 0.35);
  z-index: 100;
  width: 200px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.3s, transform 0.3s;
  font-weight: normal;
}

.tutorial-dialog[data-active="true"]{
  display: block;
  opacity: 1.0;
  transform: translateY(0);
  pointer-events: all;
}

.carat {
  position: absolute;
}

.tutorial-dialog.bottom {
  top: 100%;
  margin-top: 4px;
}

.tutorial-dialog.top {
  bottom: 100%;
  margin-bottom: 4px;
}

.tutorial-dialog.right {
  left: 100%;
  margin-left: 4px;
}

.tutorial-dialog.left {
  right: 100%;
  margin-right: 4px;
}

.tutorial-dialog.bottom[data-align="middle"]{
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-dialog.top[data-align="middle"]{
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-dialog.left[data-align="middle"]{
  top: 50%;
  transform: translateY(-50%);
}

.tutorial-dialog.left[data-align="top"]{
  top: 0;
}

.tutorial-dialog.right[data-align="middle"]{
  top: 50%;
  transform: translateY(-50%);
}

tutorial-dialog.bottom[data-align="left"]{
  left: 0;
}

.tutorial-dialog.bottom[data-align="right"]{
  right: 0;
}

.tutorial-dialog.top[data-align="right"]{
  right: 0;
}



.tutorial-dialog.bottom .carat {
  left: 10px;
  top: -10px;
}



.tutorial-dialog.bottom[data-align="middle"] .carat {
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-dialog.left[data-align="middle"] .carat {
  top: calc(50% - 6px);
  right: -10px;
  transform: rotate(90deg) translateY(-50%);
}

.tutorial-dialog.left[data-align="top"] .carat {
  top: 15px;
  right: -10px;
  transform: rotate(90deg) translateY(-50%);
}

.tutorial-dialog.bottom[data-align="right"] .carat {
  left: auto;
  right: 10px;
}

.tutorial-dialog.top .carat {
  bottom: -10px;
  left: 50%;
  transform: rotate(180deg) translateX(-50%);
}

.tutorial-dialog__actions {

  color: white;
  font-size: 12px;
  padding-top: 12px;

}

.ok {
  cursor: pointer;
  color: #fe6232;
  opacity: 0.75;

}

.ok:hover {
  opacity: 1.0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}




</style>
