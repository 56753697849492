


<template>
  <div class="">
    <div class="pt-5 pb-2">
      <v-text-field
          dense
          label="Image URL"
          placeholder="https://"
          v-model="details.url"
          hide-details
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
    name: "editor-picture",
    props: {
      details: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {
        }
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.editor-cta {

}

</style>
