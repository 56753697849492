<template>
  <div class="global-settings-editor">
    <div class="label font-weight-bold">Supported Languages:</div>
    <div class="font-weight-light py-2">
      Which languages will your landing pages be displayed in? Please add them below.<br>
      Note that there must be at least one language selected.<br>
      Each language version of the landing pages has its own text-image content. Please use the “Currently Editing” dropdown menu found on the top right corner of each editing section to switch language and fill in the corresponding text and/or images.
    </div>


    <div class="my-3">


    <v-autocomplete
        @change="($store.state.unsavedChanges = true)"
        v-model="languagesEnabled"
        :items="languageList"
        :menu-props="{ maxHeight: '400' }"
        label="Language Enabled"
        multiple

        chips
        deletable-chips
        placeholder="Search for a language"
    ></v-autocomplete>
    </div>
    <div class="my-3">


      <v-select
          @change="($store.state.unsavedChanges = true)"
          v-model="defaultLanguage"
          :items="languagesEnabledList"
          :menu-props="{ maxHeight: '400' }"
          label="Default Language"
          deletable-chips
          no-data-text="No data available"
      ></v-select>
    </div>
    <div class="my-3">
      <div class="label font-weight-bold mt-5">Page Title</div>
      <v-text-field @change="($store.state.unsavedChanges = true)" v-model="settings.pageTitle" placeholder="ScanTrust"></v-text-field>
    </div>

  </div>
</template>

<script>


export default {
  name: "global-settings-editor",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      languagesEnabled: ["en"],
      defaultLanguage: [],
      languages: [{name:"Afrikaans",key:"af"},{name:"Albanian",key:"sq"},{name:"Arabic",key:"ar"},{name:"Bulgarian",key:"bg"},{name:"Chinese",key:"zh"},{name:"Czech",key:"cs"},{name:"Danish",key:"da"},{name:"Dutch",key:"nl"},{name:"English",key:"en"},{name:"Filipino",key:"fil"},{name:"Finnish",key:"fi"},{name:"French",key:"fr"},{name:"German",key:"de"},{name:"Greek",key:"el"},{name:"Hindi",key:"hi"},{name:"Indonesian",key:"id"},{name:"Italian",key:"it"},{name:"Japanese",key:"jp"},{name:"Korean",key:"kr"},{name:"Malay",key:"ml"},{name:"Norwegian",key:"no"},{name:"Polish",key:"pl"},{name:"Portuguese",key:"pt"},{name:"Romanian",key:"ro"},{name:"Russian",key:"ru"},{name:"Serbian",key:"sr"},{name:"Slovenian",key:"sl"},{name:"Spanish",key:"es"},{name:"Swedish",key:"sv"},{name:"Thai",key:"th"},{name:"Urdu",key:"ur"},{name:"Vietnamese",key:"vi"}],
    }
  },
  methods: {},
  computed: {
    languageList: function (){
      return this.languages.map((language) =>  {
        return {
          text: language.name,
          value: language.key
        }
      })
    },
    languagesEnabledList: function (){
      return this.languagesEnabled.map((enabled) =>  {
        return this.languageList.find((item) => {
          return item.value === enabled;
        })
      })
    }
  }
}
</script>

<style scoped>


.global-settings-editor {
  padding: 20px 30px;
}

.label {
  color: #777;
  text-transform: uppercase;
  line-height: 20px;
}

</style>
