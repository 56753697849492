


<template>
  <div class="tool my-1" :class="(mode === 'edit')?'':'pa-2'" >
    <div :class="disableChildren?'disabled':''">
      <div  class="cms-tool" v-if="mode === 'edit'">
        <div  class="align-center tool-header d-flex flex-row">


          <div  class="align-center flex-row d-flex flex-grow-1 flex-shrink-1 text-truncate tool-label">
            <component :is="icon" class="v-icon notranslate ma-3 tool-icon v-icon--is-component theme--light"></component>
            <div  class="align-center text-truncate flex-shrink-1 long-and-truncated "><!----><!---->
              <div  class="text-header flex-shrink-1" v-if="!showEditor && !isPhoto">{{ editLabel }}</div><!---->
              <img v-if="!showEditor && isPhoto" :src="details.url" class="image-header" draggable="false">
            </div><!---->
          </div>

          <div class="tool-btn-ctn flex-shrink-0" v-if="showEditor">
            <div>
              <button @click="cancelEditor" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default accent--text">
                <span class="v-btn__content">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light"><g transform="translate(-308 -12)" fill="#15bbf0"><path d="M18 7.147L16.853 6 12 10.853 7.147 6 6 7.147 10.853 12 6 16.853 7.147 18 12 13.147 16.853 18 18 16.853 13.147 12z" transform="translate(308 12)"></path></g></svg>
                </span>
              </button>
              <button @click="saveEdits" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default accent--text">
                <span class="v-btn__content">
                  <svg data-v-6e2bf37c="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light"><g fill="#15bbf0" transform="translate(-346 -12)"><path d="M9.004 15.468L5.272 11.736 4 13.008 9.004 18 19.744 7.26 18.472 6z" transform="translate(346 12)"></path></g></svg>
                </span>
              </button>
            </div>
          </div>

          <div  class="tool-btn-ctn flex-shrink-0" v-else>
            <div  class="d-flex flex-row">
              <button type="button"  @click="activateEditor" v-if=isPhoto class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default primary--text"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-link theme--light"></i></span></button>
              <div tab-id="cms_01" v-if="isPhoto"><button @click="onUpload" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default primary--text"><span class="v-btn__content"><i data-v-29649009="" aria-hidden="true" class="v-icon notranslate mdi mdi-upload theme--light"></i></span></button><!----><input ref="uploader" type="file" name="image" accept="image/*" class="image-input" @change="onFileChanged"><v-dialog
                  v-model="uploadPreview"
                  persistent
                  max-width="700px"
              >

                <v-card>
                  <v-card-title class="headline" style="background-color: #f1f1f1">
                    Image Upload
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row class="">
                        <v-col class="pa-0">
                          <v-img :src="previewURL"></v-img>
                        </v-col>

                      </v-row>

                    </v-container>

                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="uploadPreview = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="saveImage"
                        :loading="isSelecting"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog></div>

              <button v-if="!isPhoto"  type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default" @click="activateEditor">
                            <span class="v-btn__content">
                              <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light"><g fill="#15bbf0" transform="translate(-310 -20)"><path d="M313.717 23.706c-.227 0-.411.19-.411.42v16.102c0 .232.19.42.419.42h16.133c.231 0 .42-.19.42-.413v-9.927c0-.29.227-.524.53-.524h.244c.293 0 .53.224.53.517v10.215c0 .776-.521 1.294-1.305 1.294h-16.971c-.784 0-1.306-.518-1.306-1.294v-16.81c0-.776.522-1.293 1.306-1.293h10.313c.288 0 .522.225.522.526v.241c0 .29-.226.526-.522.526h-9.902zm6.232 9.595l1.097-2.22 9.02-8.933c.202-.201.534-.187.72.012l.492.525c.195.208.19.538-.027.752l-9.015 8.898-2.287.966z"></path></g></svg>
                            </span>
              </button><!----><!----><!----><!---->
              <button type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default" @click="$emit('remove')">
                            <span class="v-btn__content">
                            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light"><g fill="#F43838" transform="translate(-346 -20)"><path d="M358 40.1c4.474 0 8.1-3.626 8.1-8.1s-3.626-8.1-8.1-8.1-8.1 3.626-8.1 8.1 3.626 8.1 8.1 8.1zm0 .9c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9zm-4.5-8.55c-.249 0-.45-.201-.45-.45s.201-.45.45-.45h9c.249 0 .45.201.45.45s-.201.45-.45.45h-9z"></path></g></svg>
                            </span>
              </button>
            </div>
          </div>
        </div><!---->
        <div class="px-2 pb-2" v-if="showEditor">
          <div class="edit-box px-4 py-2">
            <component :is="editor" :details="details" class="editor-component"></component>
          </div>
        </div>
      </div>
      <div class="row ma-0" v-else>
        <div class="pa-0 mr-auto col col-auto">
          <component :is="icon"></component>
          {{ label }}
        </div>
        <div class="pa-0 col col-auto" v-if="limit">
          <span v-if="false" class="limit">up to {{ limit }}</span>
          <span class="limit">$</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IconText from "@/components/IconText";
import IconCTA from "@/components/IconCTA";
import IconPicture from "@/components/IconPicture";
import IconTypeform from "@/components/IconTypeform";
import IconSocial from "@/components/IconSocial";
import EditorTextBlock from "@/components/EditorTextBlock";
import EditorCTA from "@/components/EditorCTA";
import EditorSocial from "@/components/EditorSocial";
import EditorTypeform from "@/components/EditorTypeform";
import EditorPicture from "@/components/EditorPicture";
import axios from "axios";

export default {
    name: "tool-block",
  components: {
    EditorPicture,
    EditorTypeform,
    EditorSocial, EditorCTA, EditorTextBlock, IconSocial, IconTypeform, IconCTA, IconText, IconPicture},
  props: {
      mode: {
        type: String,
        default: "tool"
      },
      allowDrag: {
        type: Boolean,
        default: false
      },

      disableChildren: {
        type: Boolean,
        default: false
      },

      details: {
        type: Object,
        default: function(){
          return {}
        }
      },
      label: {
        type: String,
        default: "Text Block"
      },
      icon: {
        type: String,
        default: "IconText"
      },

      editor: {
        type: String,
        default: ""
      },

      limit: {
        type: Number,
        default: 1
      }
  },
    data: function(){
        return {
          showEditor: false,
          uploadPreview: false,
          undo: undefined,
          selectedFile: "",
          previewURL: "",
          isSelecting: false
        }
        },
    methods: {
      onUpload: function(){

        this.$refs.uploader.value = null;
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileChanged: function(e){
        let file = e.target.files[0];
        var MAX_FILE_SIZE = 2 * 1024 * 1024;
        if(file.size > MAX_FILE_SIZE){
          alert("This file is too large. Select a smaller file.");
          return;
        }

        this.selectedFile = e.target.files[0]
        this.previewURL = URL.createObjectURL(this.selectedFile)
        this.uploadPreview = true;
      },
      saveImage: async function(){
        let uploadURL = await this.uploadPhoto();
        console.log(uploadURL);
        if(uploadURL){
          this.$store.state.unsavedChanges = true;
          this.details.url = uploadURL;
        }else{
          alert("Error");
        }

        this.uploadPreview = false;
      },
      uploadPhoto:  async function () {
        if(!this.selectedFile){ return }
        const file = this.selectedFile;

        //const filename = encodeURIComponent(file.name);
        const fileType = encodeURIComponent(file.type);
        console.log(fileType);
        const res = await fetch(`/api/upload_put?type=${fileType}`);
        console.log(res);
        const { url, key } = await res.json();
        //const formData = new FormData();

        // Object.entries({ ...fields, file }).forEach(([key, value]) => {
        //   formData.append(key, value);
        // });

        //let headers = new Headers();
        console.log(url, key);
        let putConfig = {
          headers: {
            'x-amz-acl':'public-read',
            'Content-Type': file.type
          }
        }

        const upload = await axios.put(url, file, putConfig );

        console.log(upload.data);


        if (upload.statusText == 'OK') {
          console.log('Uploaded successfully!', upload);
          return url.split('?')[0];
        } else {
          console.error('Upload failed.');
          return false;
        }


      },

      activateEditor: function(){
        this.undo = { ...this.details }
        this.showEditor = true;
      },
      cancelEditor: function(){
        if(this.undo){
          Object.keys(this.undo).forEach((key) => {
            this.details[key] = this.undo[key];
          })
        }
        this.showEditor = false;
        console.log("close editor");
        this.$emit("edit");
      },
      saveEdits: function(){
        this.$store.state.unsavedChanges = true;
        this.$store.dispatch("saveSettings");
        this.showEditor = false;
        console.log("close editor");
        this.$emit("edit");
      }
    },
    computed: {
      isPhoto: function(){
        return (this.editor === "EditorPicture")
      },
      editLabel: function(){
        if(this.isPhoto){
          return ""
        }else{
          if(this.details.text){
            return this.details.text;
          }else{
            return "Edit me! 👉"
          }
        }
      }
    }
}
</script>

<style scoped>


.tool-block {

}

.cms-tool {
  position: relative;
}

.tool .limit {
  font-size: 12px;
}

.edit-box {
  background: #fff;
  position: relative;
}


.text-header {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-input {
  display: none;
}

.image-header {
  max-height: 60px;
  margin: 0;
  display: block;
}

.disabled {
  pointer-events: none;
}


</style>
