


<template>
<div class="login">
  <div class>
    <h1>Restricted Access</h1>
    <v-text-field type="password" v-model="password" required label="Password Required" :error-messages="errors"></v-text-field>
    <v-btn class="mt-4" block color="primary" @click="onLogin">LOGIN</v-btn>
  </div>
</div>
</template>

<script>
export default {
    name: "login",
    props: {},
    data: function(){
        return {
          errors: []
        }
        },
    methods: {
      onLogin: function(){
        if(this.password!='epacDEMO4review'){
          this.errors=["Login Failed"];
        }else{
          this.errors=[];
          this.$store.commit("authorize", true);
          this.$router.push("/landing-page");
        }
      }
    },
    computed: {

    }
}
</script>

<style scoped>


.login {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

</style>
