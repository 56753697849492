<template>
  <div class="design-editor">
    <div class="">
      <div class="label font-weight-bold">Main Color:</div>
      <div class="font-weight-light pa-3 pl-0">
        The main color is used for all the tabs, buttons and links in the Landing Page. Pick the color that suits your brand the best!
      </div>
      <v-color-picker
          v-model="settings.design.color"
          @change="($store.state.unsavedChanges = true)"
          class="ma-3"
          dot-size="25"
          mode="hexa"
          swatches-max-height="150"
          show-swatches
          :swatches="swatches"
      ></v-color-picker>

    </div>
  </div>
</template>

<script>
export default {
  name: "design-editor",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    swatches: function(){
      return [
          ['#2095F2FF','#65BBEFFF'],
          ['#FF7F00FF','#F43838FF'],
        ['#51A350FF','#333333FF']
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.design-editor {
  padding: 20px 30px;
}

.label {
  color: #777;
  text-transform: uppercase;
  line-height: 20px;
}


</style>
