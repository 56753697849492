


<template>
<div class="content-block-cta">

    <div class="button-ctn typeform_jseri"><button data-v-10ab4930="" class="raised-button" :style="buttonStyle" @click="onCTA">
      {{  block.details.text }}
    </button>
    </div>

</div>
</template>

<script>
export default {
    name: "content-block-cta",
    props: {
      block: {
        type: Object,
        required: true
      },
      settings: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      onCTA: function(){
        this.$emit("overlay",this.block.details.url)
      }
    },
    computed: {
      buttonStyle: function(){
        if(this.settings.design.color){
          return {
            backgroundColor: this.settings.design.color
          }
        }

        return {}

      }
    }
}
</script>

<style scoped>


.content-block-cta {
  text-align: center;
  padding: 25px;
}

.raised-button {
  font-family: "Assistant Bold","Roboto",sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  color: #fff;
  background: blue;
  padding: 10px 24px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20);
  border: none;
}


</style>
