import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"

//import "./assets/css/styles.css"
import vuetify from './plugins/vuetify';
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'





Vue.config.productionTip = false

router.beforeEach((to, from, next) => {


  if(to.matched.some(record => record.meta.requiresAuth)){

    if(!store.state.authorized){
        //next({ name: 'Login' })
      next()
    }else{
      //console.log(to);
      next()
    }
  }else{
    next()
  }
})

Vue.use(VueGtag, {
  config: { id: "G-KDMTS69GJS" }
}, router);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
