


<template>
<div class="content-block-picture">
  <img :src="block.details.url" class="picture">
</div>
</template>

<script>
export default {
    name: "content-block-picture",
    props: {
      block: {
        type: Object,
        required: true
      },
      settings: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.content-block-picture {
  position: relative;
}

.picture {
  position: relative;
  display: block;
  width: 100%;
}

</style>
