<template>
  <div class="layout-editor">



    <div class="language-header">

        <div class="label mr-3 font-weight-bold">Currently Editing</div>
      <div>

            <v-select
                v-model="selectedLanguage"
                :items="['English']"
                no-data-text="No data available"
                solo
                dense
                hide-details
            ></v-select>
      </div>

    </div>
    <div class="pt-5">
      <div class="landing-page-header">Landing Page Layout</div>
      <div class="product-informations mx-3 pb-0">
        <div class="product-info-wrapper pa-3 d-flex flex-row flex-grow-1">
          <div class="side-area font-weight-light flex-shrink-0 px-3">
            <span class="font-weight-bold">SKU-specific content</span> is shown at the top of each landing page.<br><br>Information displayed here can be edited in the <div class="iframe-link"><a href="/#/products">Products tab</a></div> and the Scan Response settings. 👉
          <div align-content="center" justify="start" class="mt-7"><span class="font-weight-bold">Can't edit the elements?</span>
            <div class="tooltip more-tooltip ml-3">
              <i aria-hidden="true" class="v-icon notranslate info-icon mdi mdi-information theme--light" style="color: rgb(204, 194, 0); caret-color: rgb(204, 194, 0);"></i>
              <div class="tooltip-ctn pa-4">
              <span ><a href="/#/upgrade">Upgrade today </a> to unlock more features</span>
              </div>
            </div>
          </div>
          </div>
          <div class="scan-result-area flex-grow-1">
            <div class="scan-result-area-header font-weight-bold">Top of Landing Page</div>
            <div class="product-tool my-2 d-flex flex-row">
              <div class="align-center  flex-row d-flex flex-grow-1 flex-shrink-1">
                <span>Product Picture</span>
              </div>
              <div class="align-center flex-shrink-0">
                <button @click="productPictureDialog = true" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-cog-outline theme--light primary--text"></i></span></button>
                <v-dialog
                  v-model="productPictureDialog"
                  max-width="600px"
                >
                  <v-card>
                      <v-card-title>Product Picture Settings</v-card-title>
                    <v-card-text>
                      <div class="pa-6">
                        <div class="py-3">
                          <span class="description font-weight-light">
                            Users will see different product picture depending on which product they scan. You can manage this in
                            <div class="iframe-link"><a data-v-307bedcb="" href="/#/products">Products</a></div>
                          </span>
                        </div>
                        <div class="inset pt-4"><span class="font-weight-medium ">Display mode</span><div class="tooltip ml-3"><i aria-hidden="true" class="v-icon notranslate info-icon mdi mdi-information theme--light" style="color: rgb(204, 194, 0); caret-color: rgb(204, 194, 0);"></i><div class="tooltip-ctn pa-4"><span><a href="/#/upgrade">Upgrade today </a> to unlock this feature</span></div></div></div>

                          <v-radio-group class="" v-model="settings.header.picture.display" disabled>
                            <v-radio class="mb-0 dialog-form" value="contain" label="Contain"></v-radio>
                            <div class="font-weight-light pl-8 pb-3">Best suited for product image that have a white background</div>
                            <v-radio class="mb-0 dialog-form" value="cover" label="Cover"></v-radio>
                            <div class="font-weight-light pl-8 pb-3">Best suited for product image with non-white background.</div>

                            <v-radio class="mb-0 dialog-form" value="full-width" label="Full-width"></v-radio>
                            <div class="font-weight-light pl-8 pb-3">This will scale your picture to the phone width increasing the overall size.</div>

                          </v-radio-group>


                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                          @click="productPictureDialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </div>
            </div>
            <div class="product-tool my-2 d-flex flex-row">
              <div class="align-center  flex-row d-flex flex-grow-1 flex-shrink-1">
                <span>Scan Response: This code is verified</span>
              </div>
              <div class="align-center flex-shrink-0">
                <button @click="responseTextDialog = true" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-cog-outline theme--light primary--text"></i></span></button>
                <v-dialog
                    v-model="responseTextDialog"
                    max-width="600px"
                >
                  <v-card>
                    <v-card-title>Product Picture Settings</v-card-title>
                    <v-card-text>
                      <div class="pa-6">
                        <div class="py-3">
                          <span class="description font-weight-light">
                            Edit the text to display to users who scan your product's QR code
                          </span>
                        </div>
                        <div class="inset pt-4"><span class="font-weight-medium ">Scan Response Text</span><div class="tooltip ml-3"><i aria-hidden="true" class="v-icon notranslate info-icon mdi mdi-information theme--light" style="color: rgb(204, 194, 0); caret-color: rgb(204, 194, 0);"></i><div class="tooltip-ctn pa-4"><span><a href="/#/upgrade">Upgrade today </a> to unlock this feature</span></div></div></div>
                        <v-text-field v-model="settings.header.scanResponse.text" dense placeholder="This code is verified" disabled></v-text-field>


                        <div class="inset pt-4"><span class="font-weight-medium ">Powered by Scantrust</span></div>
                        <v-switch
                            disabled
                            v-model="settings.header.scanResponse.poweredByScanTrust"
                            :label="`Display the Scantrust brand text: ${settings.header.scanResponse.poweredByScanTrust?'Yes':'No'}`"
                        ></v-switch>





                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                          @click="responseTextDialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </div>
            </div>
            <div class="product-tool my-2 d-flex flex-row">
              <div class="align-center  flex-row d-flex flex-grow-1 flex-shrink-1">
                <span>Product Name</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="cms-editor d-flex flex-column mx-3">
        <div class="cms-editor-ctn pa-3 d-flex flex-row flex-grow-1">

          <div class="toolbox-ctn px-3 font-weight-light flex-shrink-0"><span class="font-weight-bold">Other content</span> on the landing page will be displayed for every product scanned.
            <br><br>
            Click an element below to add it and drag up and down to change its position. 👉
            <div class="toolbox my-6 relative">
              <Container behaviour="copy" group-name="1" :get-child-payload="getChildPayload1">
                <Draggable v-for="tool in toolList" :key="tool.id">

                    <tool-block class="build-block" :icon="tool.icon" :label="tool.label" :limit="tool.limit" @click.native="addBlock(tool)"></tool-block>

                </Draggable>
              </Container>
              <tutorial-dialog :task="1" :step="3" position="top" align="middle" style="margin-bottom: 20px;">Drag one of the blocks below over to the box on the right.</tutorial-dialog>


          </div>
          <div align-content="center" justify="start"><span class="font-weight-bold">Upgradable Features ($)</span><div data-v-b9989822="" class="tooltip more-tooltip ml-3"><i aria-hidden="true" class="v-icon notranslate info-icon mdi mdi-information theme--light" style="color: rgb(204, 194, 0); caret-color: rgb(204, 194, 0);"></i><div data-v-b9989822="" class="tooltip-ctn pa-4"><span data-v-b9989822="">Features available here are what will be available in the Plus plan.</span></div></div></div></div>

          <div class="flex-grow-1 flex-shrink-1 relative">
            <tutorial-dialog :task="1" :step="4" position="top" align="right" style="margin-bottom: -30px;margin-right:-10px;">Click the icons below and edit the blocks content.</tutorial-dialog>
            <div class="drop-area-ctn pa-2">
              <div class="drop-area-header  font-weight-bold">Rest of Landing Page</div>
              <div class="drop-area flex-shrink-1">
                <Container group-name="1" :get-child-payload="getChildPayload2" @drop="onDrop('items2', $event)" style="height:100%;">
                  <Draggable v-for="block in settings.body" :key="block.blockId">
                    <tool-block :icon="block.icon" :label="block.label" :limit="block.limit" :mode="block.mode" :editor="block.editor" :details="block.details"  @remove="removeBlock(block)" @edit="onBlockEditorClose" ></tool-block>
                  </Draggable>
                </Container>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import ToolBlock from "@/components/ToolBlock";
import TutorialDialog from "@/components/TutorialDialog";
import { Container, Draggable } from "vue-smooth-dnd";
import { applyDrag } from '../utils/helpers'
export default {
  name: "layout-editor-d",
  components: {TutorialDialog,  ToolBlock, Container, Draggable},
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      test: true,
      productPictureDialog: false,
      responseTextDialog: false,
      selectedLanguage: "English",
      dragItem: undefined,
      dragCounter: 0,
      dragIndex: null,
      isDraggingTool: true,
      tools: {
        textBlock: {
          id: "textBlock",
          icon: "IconText",
          label: "Text Block",
          editor: "EditorTextBlock",
          details: {
            text: ""
          },
          limit: 1
        },
        cta: {
          id: "cta",
          icon: "IconCTA",
          label: "Call-To-Action Button",
          editor: "EditorCTA",
          details: {
            text: "",
            link: ""
          },
          limit: 2
        },
        picture: {
          id: "picture",
          icon: "IconPicture",
          label: "Picture",
          editor: "EditorPicture",
          details: {
            url: ""
          },
          limit: 10
        },
        typeform: {
          id: "typeform",
          icon: "IconTypeform",
          label: "Typeform Pop-up",
          editor: "EditorTypeform",
          details: {
            text: "",
            url: "",
            type: "manual"
          },
          limit: 0
        },
        social: {
          id: "social",
          icon: "IconSocial",
          label: "Social Media Links",
          editor: "EditorSocial",
          details: {
            type: "",
            text: "",
            url: ""
          },
          limit: 3
        }
      },
      items2: [

      ],
      testItems2: []
    }
  },
  methods: {

    onDrop (collection, dropResult) {
      dropResult.payload = this.generateBlock(dropResult.payload);
      dropResult.payload.mode = "edit";
      this.settings.body = applyDrag(this.settings.body, dropResult)
      if(this.$store.state.demo.task === 1){
        this.$store.dispatch("triggerStep", { task: 1, step: 4});
      }
    },

    getChildPayload1 (index) {
      return this.toolList[index]
    },

    getChildPayload2 (index) {
      return this.settings.body[index]
    },

    onDragStart: function(e,tool){

      if(tool){

        this.isDraggingTool = true;
        this.dragIndex = null;

        e.dataTransfer.dropEffect = 'move'
        e.dataTransfer.effectAllowed = 'move'

        this.dragItem = this.generateBlock(tool);

        console.log(tool.id,this.dragItem.id);
      }



    },

    generateBlock: function(tool, mode){
      let blockId = Date.now();
      let block = { ...tool, blockId:blockId };
      if(mode){
        block.mode = mode
      }else{
        block.mode = "build"
      }
      let details = { ...tool.details };
      block.details = details;
      return block;
    },

    addBlock: function(tool){
      let block = this.generateBlock(tool,"edit")
      this.settings.body.push(block);
      this.$store.state.unsavedChanges = true;
      this.$store.dispatch("saveSettings");
    },

    onBlockEditorClose: function(){

      console.log("close");
      if(this.$store.state.demo.task === 1){
        this.$store.dispatch("setTask", { task: 2, step: 1});
      }

    },

    removeBlock: function(tgt){
      let blockIndex = this.settings.body.findIndex((block) => {
        return block.blockId === tgt.blockId;
      })

      this.settings.body.splice(blockIndex,1);
      this.$store.state.unsavedChanges = true;
      this.$store.dispatch("saveSettings");

    },

    onCopyDrop: function(collection, dropResult){
      this.settings.body = applyDrag(this.settings.body, dropResult)
    },

    onDropOLd: function(e){
      console.log("Drop", e);

      if(e){
        return;
      }

      this.dragCounter = 0;

      let tgtIndex = this.settings.body.length;

      if(this.dragItem){

        let block = this.dragItem;

        if(this.dragIndex){
          let rect = this.dragIndex.getBoundingClientRect();
          console.log((e.clientY - rect.top), (rect.bottom - rect.top));

          let next = 0;

          if (((e.clientY - rect.top)/(rect.bottom - rect.top)) > .5){
            next = 1;
          }

          tgtIndex = next + this.settings.body.indexOf(this.dragIndex);

        }


        this.settings.body.splice(tgtIndex, 0, block);

        this.dragItem.mode = "edit";
        this.$store.state.unsavedChanges = true;

      }

      this.isDraggingTool = false;
      this.dragItem = undefined;

      if(this.$store.state.demo.task === 1){
        this.$store.dispatch("triggerStep", { task: 1, step: 4});
      }

    },

    onDragOver: function(e){
      e.preventDefault();
    },

    onDragEnter: function(e,tgt){

        if(this.dragItem){
          console.log("DragEnter");
          if(!this.dragCounter){

            //let block = this.dragItem;
            //this.settings.body.push(block);
          }else{
            if(e && tgt){

              //console.log(this.dragItem.blockId, tgt.blockId);



              this.dragIndex = e.target;

              // if(tgt.blockId !== this.dragItem.blockId){
              //   let tgtIndex = this.settings.body.findIndex((el) => {
              //     return (el.blockId === tgt.blockId);
              //   })
              //
              //   if(tgtIndex > -1){
              //     console.log("sort")
              //   }
              // }else{
              //   console.log("same item")
              // }



            }
          }
          this.dragCounter++;
        }



    },

    onDragLeave: function(){

      if(this.dragItem){

        this.dragCounter--;

        console.log("leave");

        if(!this.dragCounter){
          // let blockIndex = this.settings.body.findIndex((block) => {
          //   return block.blockId === this.dragItem.blockId;
          // })
          //
          // this.settings.body.splice(blockIndex,1);
        }
      }
    },

    onDragEnd: function(e){
      console.log("Drag End");



      if(this.dragItem){
        this.dragItem.mode = "edit";
        this.$store.state.unsavedChanges = true;

      }

      this.dragItem = undefined;
      this.isDraggingTool = false;
      this.dragCounter = 0;
      e.preventDefault();
    }
  },
  computed: {
    toolList: function(){
      return [this.tools.textBlock, this.tools.cta, this.tools.picture, this.tools.typeform,this.tools.social]
    }
  }
}
</script>

<style scoped>


.layout-editor {
  position: relative;
  height: 100%;
  overflow-y: scroll;


}

.language-header {
  height: 54px;
  background-color: rgba(7,137,178,.2);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.label {
  text-transform: uppercase;
  color: #777;
  font-size: 13px;
}

.landing-page-header {
  background: #f1f1f1;
  padding: 10px 0 0 15px;
  font-weight: 700;
  font-size: 14px;
  margin: 0 12px;
}

.product-informations {
  padding: 10px;
  background: #f1f1f1;
}

.product-info-wrapper {
  background: #fff;
}

.side-area {
  max-width: 270px;
}

.scan-result-area {
  padding: 8px;
  min-width: 265px;
  border: 2px dashed rgba(7,137,178,.5);
}

.scan-result-area-header {
  width: 100%;
  text-align: center;
  color: rgba(7,137,178,.5);
}

.product-tool {
  padding: 5px 10px;
  min-height: 46px;
  background: #f1f1f1;
  border-radius: 6px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,0.3);
}

.iframe-link {
  display: inline;
}

a {
  font-weight: 700!important;
  color: #0789b2!important;
}

.tooltip {
  position: relative;
  width: 30px;
  padding-top: 5px;
  display: inline-block;
}



.tooltip-ctn {
  display: none;
  font-family: Roboto;
  font-size: 13px;
  left: 30px;
  bottom: 5px;
  position: absolute;
  background: #fff!important;
  color: #0789b2!important;
  opacity: 1!important;
  width: 250px;
  border-radius: 8px;
  -webkit-box-shadow: 0 8px 56px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 8px 56px 0 rgba(0, 0, 0, 0.35);
  z-index: 200;
}

.tooltip:hover .tooltip-ctn {
  display: block;
}

.cms-editor {
  height: 100%;
  overflow-y: visible;
  padding: 10px;
  background: #f1f1f1;
  position: relative;
}

.cms-editor-ctn {
  background: #fff;
  position: relative;
}

.toolbox-ctn {
  max-width: 270px;
}

.drop-area-ctn {
  border: 2px dashed rgba(7,137,178,.5);
  position: relative;
  overflow-x: hidden;
  height: 100%;
}

.drop-area {
  height: calc(100% - 30px);
  padding-top: 10px;

}

.drop-area-header {
  width: 100%;
  text-align: center;
  color: rgba(7,137,178,.5);
}

.tool {
  width: 100%;
  background: #f1f1f1;
  border-radius: 6px;
  cursor: grab;
  -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.30);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.30);
}

.v-card__title {
  background-color: #f1f1f1;
}

.description {
  font-size: 16px;
  color: #333;
}

.dialog-form label {
  color: rgba(0,0,0,.6)!important;
}

.draggable-item {
  padding: 2em;
  border: 1px solid black;
  background-color: #f1f1f1;
  margin: 0.5em;
}

.list-complete-item {
  transition: all 1s;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}




</style>
