


<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light black--text"><path d="M7.574 14.464c-.688.788-1.699 1.286-2.824 1.286C2.683 15.75 1 14.068 1 12s1.683-3.75 3.75-3.75c1.125 0 2.136.498 2.824 1.285l8.11-4.623c-.12-.366-.184-.757-.184-1.162C15.5 1.682 17.183 0 19.25 0S23 1.682 23 3.75 21.317 7.5 19.25 7.5c-1.125 0-2.136-.498-2.824-1.285l-8.11 4.624c.12.365.184.756.184 1.161s-.065.795-.184 1.161l8.11 4.624c.689-.787 1.7-1.285 2.824-1.285 2.067 0 3.75 1.682 3.75 3.75S21.317 24 19.25 24s-3.75-1.682-3.75-3.75c0-.406.065-.796.184-1.162l-8.11-4.624zM19.25 1.5C18.01 1.5 17 2.509 17 3.75S18.01 6 19.25 6s2.25-1.009 2.25-2.25-1.01-2.25-2.25-2.25zm0 16.5C18.01 18 17 19.009 17 20.25s1.01 2.25 2.25 2.25 2.25-1.009 2.25-2.25S20.49 18 19.25 18zM4.75 9.75C3.51 9.75 2.5 10.759 2.5 12s1.01 2.25 2.25 2.25S7 13.241 7 12 5.99 9.75 4.75 9.75z"></path></svg>
</template>

<script>
export default {
    name: "icon-social",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.icon-social {

}

</style>
