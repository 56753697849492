


<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light black--text"><g><path d="M21 0H3C1.345 0 0 1.345 0 3v18c0 1.655 1.345 3 3 3h18c1.655 0 3-1.345 3-3V3c0-1.655-1.345-3-3-3zM3 2h18c.552 0 1 .448 1 1v7.086l-3.293-3.293c-.39-.39-1.023-.39-1.414 0l-7.043 7.043-2.543-2.543c-.39-.39-1.023-.39-1.414 0L2 15.586V3c0-.552.448-1 1-1zm18 20H3c-.552 0-1-.448-1-1v-2.586l5-5 5.793 5.793c.196.196.45.293.707.293.256 0 .511-.097.707-.293.39-.39.39-1.023 0-1.414l-2.543-2.543L18 8.915l4 4V21c0 .552-.448 1-1 1z"></path><path d="M8 6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"></path></g></svg>
</template>

<script>
export default {
    name: "icon-picture",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.icon-picture {

}

</style>
