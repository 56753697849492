


<template>
  <div class="">
    <div class="pt-2 pb-2">
      <v-text-field
          dense
          label="Button Text"
          placeholder="Follow Us"
          v-model="details.text"
          hide-details
      ></v-text-field>
    </div>
    <div class="pt-5 pb-2">
      <v-text-field
          dense
          label="Typeform URL"
          placeholder="https://"
          v-model="details.url"
          hint="Eg: https://scantrust.typeform.com/to/XXXXX"
          persistent-hint
      ></v-text-field>
    </div>
    <v-container class="px-0 py-0" fluid>
      <v-radio-group v-model="details.type">
        <v-radio color="primary" label="Automatic" value="automatic"></v-radio>
        <div class="font-weight-light ml-8">The survey will open automatically once the user scrolls down to this point</div>
        <v-radio class="mt-5" color="primary" label="Manual" value="manual"></v-radio>
        <div class="font-weight-light ml-8">The survey will only open when the user clicks on the button</div>


      </v-radio-group>
    </v-container>
  </div>
</template>

<script>
export default {
    name: "editor-typeform",
    props: {
      details: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {
        }
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.editor-cta {

}

</style>
