


<template>
  <div class="content-preview" :data-preview-mode="previewMode" ref="preview">
    <div class="preview-wrapper">
      <div class="product-info">
        <div class="product-infos">
          <div class="product-image" :style="backgroundImageStyle"></div>
          <div class="product-infos-block">
            <div class="product-infos-block-items" v-if="settings">
              <div class="authentication-result font-weight-bold">{{ settings.header.scanResponse.text }}</div>
              <div class="powered-by">Authentication powered by Scantrust</div>
              <div class="product-name">{{ settings.product.name }}</div>
            </div>
          </div>
        </div>

      </div>
      <div class="cms-body">
        <component :is="blockTypes[block.id]" v-for="block in settings.body" :key="block.blockId" :block="block" :settings="settings" @popup="showPopup" @overlay="showOverlay"></component>
      </div>
      <div class="footer" :style="footerStyle">
        <div class="st-logo"><img src="/scantrust-s.png" alt="company logo" class="logo"></div>
        <div class="font-bold provided-by">
          Information updated by
        </div>
        <div class="company-infos">Powered by Scantrust<br>© 2021 Scantrust SA</div>
        <div class="policy-links" :style="footerLinkStyle">
          <a href="https://www.scantrust.com/privacy">Privacy Policy</a>
          <a>Cookie Policy</a>
        </div></div>

      <div class="popup-ctn" v-if="popup">
        <div></div>
        <div data-qa="popup-mode-popup" mode="popup" width="800" class="popup" >
          <img @click="closePopup" alt="close-typeform" data-qa="popup-close-button" mode="popup" src="data:image/gif;base64,R0lGODlhEQARAIAAAODn7P///yH5BAEHAAEALAAAAAARABEAAAIqBIKpab3v3EMyVHWtWZluf0za0XFNKDJfCq5i5JpomdUxqKLQVmInqyoAADs=" class="popup-close">
          <iframe :src="popupURL" data-qa="iframe" frameborder="0" height="100%" width="100%" style="border: 0px; -webkit-mask-image: -webkit-radial-gradient(center, circle cover, white, black); transform: translateZ(0px);"></iframe>
        </div>
      </div>
      <div class="overlay" v-if="overlay">
        <div class="close icon" @click="closeOverlay">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#333333" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
        </div>
        <div class="content">This feature is disabled on the live preview. User would be redirected to:<br><br><a :href="overlay">{{ overlay }}</a></div>
      </div>
    </div>


  </div>
</template>

<script>
import ContentBlockText from "@/components/ContentBlockText";
import ContentBlockCTA from "@/components/ContentBlockCTA";
import ContentBlockPicture from "@/components/ContentBlockPicture";
import ContentBlockSocial from "@/components/ContentBlockSocial";
import ContentBlockTypeform from "@/components/ContentBlockTypeform";
export default {
    name: "content-preview",
  components: {ContentBlockTypeform, ContentBlockSocial, ContentBlockPicture, ContentBlockCTA, ContentBlockText},
  props: {
      settings: {
        type: Object,
        required: true
      },
    previewMode: {
      type: Boolean,
      default: false
    }
    },
    data: function(){
        return {
          popup: false,
          popupURL: "",
          overlay: ""
        }
        },
    methods: {
      showPopup: function(url){
        console.log(url, this.previewMode);

        if(this.previewMode){
          this.popupURL = url;
          this.popup = true;
          this.$refs.preview.scrollTo(0,0);
        }else{
          if(this.popupURL){
            window.open(this.popupURL, '_blank');
          }

        }


        //this.refs.content
      },

      closePopup: function(){
        this.popup = false;
        this.popupURL = ""
      },
      showOverlay: function(url){
        if(url){
          if(this.previewMode){
            this.overlay = url;
            this.$refs.preview.scrollTo(0,0);
          }else{
            window.open(url, '_self');
          }

        }
      },

      closeOverlay: function(){

        this.overlay = ""
      }
    },
    computed: {
      backgroundImageStyle: function(){
        return {
          backgroundImage: `url(${this.settings.product.image})`
        }
      },
      footerStyle: function(){
        if(this.settings.design.color){
          return {
            borderBottom:  `7px solid ${this.settings.design.color}`
          }
        }

        return {}
      },

      footerLinkStyle: function(){
        if(this.settings.design.color){
          return {
            color: this.settings.design.color
          }
        }

        return {}
      },
      blockTypes: function(){
        return {
          "textBlock": "ContentBlockText",
          "cta": "ContentBlockCTA",
          "picture": "ContentBlockPicture",
          "typeform": "ContentBlockTypeform",
          "social": "ContentBlockSocial"
        }
      }
    }
}
</script>

<style scoped>
.content-preview {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-size: 16px;
}

.content-preview[data-preview-mode="true"] {
  font-size: 11px;
}

.preview-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}




.product-info {
  position: relative;
}

.product-infos {
  position: relative;
  height:100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  display: block;
  box-shadow: inset 0 0 18px 0 rgba(0, 0, 0, 0.1);
  flex-grow: 0;
  flex-shrink: 0;
  color: #2c3e50;
  height: 0;
  padding-bottom: 96%;
  width: 100%;
  position: relative;
}



.product-infos-block {
  align-items: center;
  display: flex;
  margin: 10px 10px;
}

.authentication-result {
  font-size: 1.5em;
  line-height: 1.2;
  padding-bottom: 5px;
  margin: 0 5px;
  color: #65BBEF;
}

.powered-by {
  padding-bottom: 6px;
  font-family: sans-serif;
  color: #333;
  font-size: 0.6em;
  margin: 0 5px;

}

.product-name {
  margin: 0 5px;
  font-size: 1.2em;
}

.footer {
  direction: ltr!important;
  position: relative;
  background: white;
  background-position: 50%;
  padding: 10px 20px;
  color: #333;
  font-family: "Assistant Light",Roboto, sans-serif;
  border-top: 14px solid rgba(0,0,0,.1);
}

.footer .logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
}

.footer a {
  font-size: 9px;
  color: currentColor;
}

.provided-by {
  font-size: 1.1em;
  font-weight: bold;
}

.company-infos {
  font-size: 0.8em;
}

.policy-links {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
}

.popup-ctn {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 200ms ease,visibility 0s linear 0s;
  transition: opacity 200ms ease,visibility 0s linear 0s;
  background: rgba(0,0,0,0.85);
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
}

.content-preview[data-preview-mode="true"] .popup-ctn {
  position: absolute;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

}

.popup {
  visibility: visible;
  opacity: 1;
  position: fixed;
  max-width: 100%;
  width: calc(100vw - 80px);
  height: calc(100vh - 80px);
  top: 40px;
  left: 40px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.content-preview[data-preview-mode="true"] .popup {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 80px);
  top: 35px;
  left: 20px;
}

.popup-close {
  position: absolute;
  padding: 8px;
  cursor: pointer;
  width: 30px;
  max-width: 30px;
  top: -34px;
  right: -34px;
}

.content-preview[data-preview-mode="true"] .popup-close {
  top: -28px;
  right: -20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content-preview[data-preview-mode="true"] .overlay {
  position: absolute;
}

.overlay .close {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 24px;
  right: 10px;
  color: #333;
}

.overlay .content {
  text-align: center;
  font-size: 16px;
  color: #333;
  font-family: Assistant Bold;
  word-break: break-word;
}


</style>
