


<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light black--text"><g><path d="M2.551 6.365c.383-.066.64-.43.574-.813-.375-2.17 1.299-4.146 3.487-4.146 2.181 0 3.858 1.967 3.488 4.138-.065.383.193.746.576.811.383.065.746-.192.81-.575C12.002 2.759 9.67 0 6.613 0 3.558 0 1.215 2.758 1.739 5.792c.066.383.431.639.812.573z" transform="translate(3)"></path><path d="M14.094 11.28c-.527-1.034-1.783-1.447-2.813-.937-.479-.94-1.574-1.385-2.57-1.039V4.998c0-1.148-.922-2.107-2.056-2.138-1.189-.034-2.173.918-2.173 2.109V15.8l-.826-.865c-.793-.83-2.12-.889-2.96-.13-.885.8-.93 2.163-.114 3.02l4.747 5.063C5.991 23.595 6.926 24 7.894 24h5.726c1.938 0 3.515-1.577 3.515-3.516v-7.312c0-1.558-1.643-2.584-3.041-1.892zm1.635 9.204c0 1.163-.946 2.11-2.11 2.11H7.895c-.58 0-1.142-.243-1.539-.667l-4.754-5.07c-.271-.285-.258-.74.038-1.008.28-.252.728-.226 1 .058l2.037 2.134c.437.457 1.212.147 1.212-.486V4.97c0-.395.328-.714.728-.703.38.01.689.339.689.732 0 7.737-.014 4.23-.014 8.408 0 .39.318.707.71.703.392.004.71-.313.71-.703v-2.243c.062-.324.348-.57.69-.57.387 0 .703.316.703.704v2.11c0 .388.315.702.703.702.388 0 .703-.314.703-.703v-1.172c0-.387.316-.703.703-.703.388 0 .703.316.703.703v1.172c0 .389.315.703.704.703.388 0 .703-.314.703-.703v-.234c0-.388.315-.703.703-.703.387 0 .703.315.703.703v7.312z" transform="translate(3)"></path></g></svg>
</template>

<script>
export default {
    name: "icon-cta",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.icon-cta {

}

</style>
