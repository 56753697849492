


<template>
<div class="tab-nav">
    <div v-for="(tab, index) in tabs" class="tab" :key="index" @click="$emit('select', index)" :data-active="(index == selection)" >
      <div class="mark"></div>
      <div class="inner-tab">
        <div class="font-weight-bold">{{ tab.label }}</div>
        <div class="font-weight-light">{{ tab.description }}</div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    name: "tab-nav",
    props: {
      selection: {
        type: Number,
        default: 0
      },
      tabs: {
        type: Array,
        default: function(){
          return [];
        }
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>

.tab-nav {
  position: relative;
}

.tab {
  position: relative;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
}

.inner-tab {
  padding: 11px;
  line-height: 20px;
  padding-left: 19px;
  box-sizing: border-box;
}


.tab[data-active] {
  background-color: white;
  border: none;

}

.mark {
  width: 8px;
  height: 100%;
  flex: 0 0 auto;
  position: absolute;
  left: 0;
  top: 0;
}

.tab[data-active] .mark{
  background-color: var(--primary-blue);
}

.tab[data-active] .inner-tab {
  border-right-color: white;
  border-top-color: white;
  border-bottom-color: white;
}

</style>
