


<template>
  <div>
  <v-dialog
      v-model="registration"
      max-width="700px"
      persistent
      overlay-opacity="0.8"
      overlay-color="#000"
  >
    <v-card dark outlined class="pa-8">
      <v-card-title class="title">
        <div class="pb-4">
          <img class="logo" src="/img/epac-logo.png" />
        </div>
        <div>
          <span class="window-title">{{ currentTitle }}</span>
        </div>
      </v-card-title>
      <v-window v-model="step" >
        <v-window-item :value="0">
          <v-card-text>
            <div class="px-2">

              <p>ePacConnect is an innovative Connected Packaging platform for brands of all sizes.  With our solution, you can connect with your consumers, protect your brand, and manage your supply chain, all while collecting valuable analytics on your consumer’s behaviour.</p>
              <p class="mobile-warning">Your browser window is currently too small to run this demo. If you can, try to make your window larger until this warning goes away. If you are on a mobile device, please try going to this url on a device with a larger screen (desktop or laptop is preferable).</p>

            </div>
            <div class="d-flex justify-center pt-8">
              <v-btn rounded color="#fe6232" @click="nextStep">Get Started</v-btn>
            </div>

          </v-card-text>
        </v-window-item>
        <v-window-item :value="1">
          <v-card-text>
            <div class="px-4">

                <p>Enter your email address, verify it, and access our demo portal.  Once complete, we send you a unique QR code that you can share with your friends and colleagues to show off your Connected Packaging mobile interface.</p>
              <p>By entering, you agree to our <a href="https://epacflexibles.com/privacy-policy/" target="_blank">privacy policy</a></p>

            </div>
            <v-row class="px-2 pt-4">
              <v-col>
                <v-text-field

                    v-model="email"
                    label="Your Email"
                    outlined
                    hint="Valid email required"
                    persistent-hint
                    placeholder="you@example.com"
                    :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="2">
          <v-card-text>
            <div class="px-2">We just sent a verification email to <b>{{ email }}</b>. Please check you inbox and enter the verification code we sent you in the field below, then click "Verify Code" to continue.</div>
            <v-row class="justify-center mt-4">
              <v-col cols="12" sm="6">
                <input
                    maxlength="6"
                    class="code-input"
                    v-model="verificationCode"
                    placeholder="ENTER CODE HERE"
                >
                <div class="code-error">{{ codeMsg }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-text>
            <div class="px-2">
              <p>Your email has been verified. You can now use <b>{{ email }}</b> to re-enter this demo at any time.</p>
              <p>The following application is a fully functional environment that will allow to create your own custom landing page that can be linked to via a QRCode on your ePac packaging. We have included a <b>Guided Tour</b> feature that will walk you through how easy it is to create a custom landing page.</p>
            </div>

          </v-card-text>
        </v-window-item>
      </v-window>
      <v-card-actions class="justify-center" v-if="step > 0">
        <v-btn
            class="back-btn"
            :disabled="step === 0"
            plain
            @click="goBack"
            v-if="step > 0"

        >
          <v-icon dark>
            mdi-arrow-left
          </v-icon>
        </v-btn>



        <v-btn rounded color="#fe6232" class="px-4" :disabled="((step > 1) && !isValidEmail)" @click="nextStep">{{ nextLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
    name: "registration-panel",
    mounted() {
      this.email = this.$store.state.reviewer;
      this.$gtag.pageview({
        page_path: '/registration',
      })
    },
  props: {
      registration: {
        type: Boolean,
        default: false
      }
    },
    data: function(){
        return {
          processing: false,
          step: 0,
          email: "",
          handshake: "",
          verificationCode: "",
          codeMsg: "",
          rules: {
            verficationCode: (value) => {
              return (value.length === 6) || "6 digit code required."
            },
            required: (value) => {
              return !!(value) || "Valid e-mail required."
            },
            email: () => {
              return this.isValidEmail || "Invalid e-mail."
            }
          }
        }
        },
    methods: {

      goBack: function(){
        if(this.step === 3){
          this.step = 1;
          return;
        }else{
          this.step--;
        }

      },

      nextStep: async function(){
        this.codeMsg = ""
        if(this.step === 3){
          this.$emit("registered");
          this.$gtag.event("login", {
            'event_category': "engagement",
            'event_label': "method"
            })
          return;
        }

        if(this.step === 2){
          if(!this.validCode){
            this.codeMsg = "INVALID CODE. TRY AGAIN."
            return;
          }

          let verification = await this.$store.dispatch("verifyCode", {
            code: this.verificationCode,
            handshake: this.handshake
          })

          console.log("verification", verification);

          if(verification && verification.verified){
            this.$store.state.settings.verified = true;
            this.$store.state.unsavedChanges = true;
            this.$store.dispatch("saveSettings");
            this.step++;
            return;
          }else{
            console.log("Verification failed!")
            this.codeMsg = "VERIFICATION FAILED. TRY AGAIN."
            return;
          }



        }

        if(this.step === 1){
          if(!this.isValidEmail){
            return
          }

          let reviewer = this.email.toLowerCase();

         //this.$store.state.reviewer = reviewer;

          this.handshake = "";



          let archive = await this.$store.dispatch("loadSession", reviewer);
          if(archive && archive.verified){
            console.log("session verified");

            this.step = 3;
            return;
          }else{
            console.log("need to verify user");
            this.$store.state.reviewer = reviewer;
            this.$store.state.settings.reviewer = reviewer;



            if(archive && !archive.verified){
              console.log("user is in system, but never verified");
            }else{
              console.log("this is a new user");
              let session = await this.$store.dispatch("initUser",  this.$store.state.settings).catch((error) => {
                console.log(error);
              });

              this.$gtag.event("registration", {
                'event_category': "engagement",
                'event_label': "method"
              })

              if(session && session.data){
                console.log("new user session initiated for", reviewer, session);
                session.data.id = session.ref["@ref"].id;
                this.$store.state.settings = session.data;
                localStorage.setItem("reviewer", reviewer);
                //localStorage.setItem("sessionId", Date.now());
              }else{
                alert("Something went wrong. Please try again later.");
                return;
              }
            }

            this.$gtag.event("verification-request", {
              'event_category': "engagement",
              'event_label': "method"
            })

            let verificationSent = await this.$store.dispatch("initVerification",  reviewer);
            console.log("verification info", verificationSent);

            if(!verificationSent || !verificationSent.handshakeId){
              alert("Something went wrong. Please try again later.")
              return;
            }else{
              this.handshake = verificationSent.handshakeId;
            }


            this.step = 2;
            return;
          }


        }
        this.step++;
      }
    },
    computed: {

      validCode: function(){
        return this.verificationCode.length === 6;
      },

      isValidEmail: function(){

          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))
          {
            return (true)
          }

          return (false)

      },


      currentTitle () {
        switch (this.step) {
          case 0: return 'Welcome to ePacConnect!'
          case 1: return 'Enter your Email'
          case 2: return 'Please verify your email to begin'
          default: return "Thank you!"
        }
      },

      nextLabel () {
        switch (this.step) {
          case 0: return 'Get Started'
          case 1: return 'Submit'
          case 2: return 'Verify Code'
          default: return "Enter Demo"
        }
      }
    }
}
</script>

<style>

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

</style>

<style scoped>


.registration-dialog {

}

.title {
  display: block;
  text-align: center;
}

.logo {
  width: 160px;
}


.window-title {
  font-size: 36px;
  font-weight: normal;
  overflow-wrap: normal;
}

.back-btn {
  position: absolute;
  left: 42px;
}

.centered-input >>> input {
  text-align: center
}

.code-input {
  text-align: center;
  border: 1px solid #888;
  padding: 12px 6px;
  font-size: 20px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
  color:white;
}

.code-input:hover {
  border-color: white;
}

.code-error {
  font-size: 12px;
  color: red;
  text-align: center;
}

.mobile-warning {
  color: red;
  font-size: 1.5em;
  line-height: 1.3em;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 1050px){
  .mobile-warning {
    display: none;
  }
}






</style>
