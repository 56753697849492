import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import LandingPage from "@/views/LandingPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    redirect: "/landing-page?tab=editor",
    meta: {
      module: "home",
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      module: "home",
    }
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    meta: {
      module: "home",
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
    meta: {
      module: "products",
      requiresAuth: true
    }
  },
  {
    path: '/landing-page',
    name: 'LandingPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LandingPage,
    meta: {
      module: "landing-page",
      requiresAuth: true
    }
  },
  {
    path: '/Upgrade',
    name: 'Upgrade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Upgrade.vue'),
    meta: {
      module: "upgrade",
      requiresAuth: true
    }
  },
  {
    path: '/preview',
    name: 'Preview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Preview.vue'),
    meta: {
      module: "preview",
      requiresAuth: false
    }
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})


export default router
