<template>
  <div class="redirect-editor">
    <div class="">
      <div class="label font-weight-bold">Choose a redirect rule for your QR codes:</div>

      <v-container class="px-0 py-0" fluid>
        <v-radio-group v-model="settings.redirect.type" @change="($store.state.unsavedChanges = true)">
          <v-radio color="primary" label="Redirect users to a custom landing page" value="custom"></v-radio>
          <div class="font-weight-light inset">When they scan a QR code, users will be redirected to a customized landing page which you can edit on the Scantrust platform. See "Landing Page Editor" on the left.</div>
          <v-radio class="mt-5 mb-0" color="primary" label="Redirect users to one URL" value="static"></v-radio>
          <div class="inset">
            <div class="font-weight-light">All QR code scans will redirect users to the URL you input here.</div>
            <div class="mt-5">
            <v-text-field
                @change="($store.state.unsavedChanges = true)"
                :disabled="!(settings.redirect.type === 'static')"
                autofocus
                dense
                label="Enter a URL"
                placeholder="Default Value"
                persistent-hint
                :rules="urlRules"
                v-model="settings.redirect.url"

            ></v-text-field>
            </div>
          </div>
          <v-radio class="mt-5" color="primary" label="Redirect users to a product-specific URL" value="product"></v-radio>
          <div class="font-weight-light inset">When users scan your products' QR codes, they will be redirected to a different URL depending on the product scanned. You must have already defined a product URL here for each product.</div>


        </v-radio-group>
      </v-container>

    </div>
  </div>
</template>

<script>
export default {
  name: "redirect-editor",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isValid: false
    }
  },
  methods: {},
  computed: {
    urlRules: function(){
      return [
        () => !!this.settings.redirect.url || "URL Required",
        () => !!this.isValidURL || 'Valid URL Required'
      ]
    },

    isValidURL: function(){
      let url;
      try {
        url = new URL(this.settings.redirect.url);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  }
}
</script>

<style scoped>


.redirect-editor {
  padding: 20px 30px;
}

.label {
  color: #777;
  text-transform: uppercase;
  line-height: 20px;
}

.inset {
  padding-left: 30px;
}

</style>
