


<template>
  <div class="pb-2 editor-text-block">
    <p class="editor-text-block-input" contenteditable v-text="text" @input="onEditInput" :data-empty="!details.text"></p>
    <p class="bottom-border"></p>
  </div>
</template>

<script>
export default {
    name: "editor-text-block",
    mounted() {
      this.text = this.details.text;
    },
  props: {
      details: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {
          text: ""
        }
        },
    methods: {
      onEditInput: function(e){

        this.details.text = e.currentTarget.innerText;

      }
    },
    computed: {}
}
</script>

<style scoped>


.editor-text-block {
  width: 100%;
  position: relative;
  max-width: 100%;
}

.editor-text-block-input {
  width: 100%;
  position: relative;
  max-width: 300px;
  outline: none;

}

.input-container {
  width: 100%;
  position: relative;
}

.bottom-border {
  border-bottom: 1px solid black;
  width: 100%;
  height: 1px;
  margin-bottom: 0;
}

.editor-text-block-input[data-empty]::after {
  content: "Enter you text here.";
  opacity: 0.5;
  font-style: italic;
}

</style>
