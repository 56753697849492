


<template>
<div class="nav-link" :class="active?'active':''" :data-disabled="disabled">
  <slot class="link-content"></slot>
</div>
</template>

<script>
export default {
    name: "nav-link",
    props: {
      active: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>

/*flex items-center border-b-6 border-transparent font-medium text-gray-800 mx-4*/

.nav-link {
  border-bottom: 6px solid transparent;
  font-weight: 500;
  margin: 0 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: -1px;
  position: relative;
}

.nav-link[data-disabled]{
  pointer-events: none;
  opacity: 0.5;
}

.nav-link a {
  color: rgb(51, 51, 51);

  text-decoration: none;

}

.nav-link.active {
  border-color: var(--primary-blue);
}

</style>
