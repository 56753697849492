


<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light black--text"><path d="M20.516 0c.388 0 .703.315.703.703v22.594c0 .388-.315.703-.703.703H2.703C2.315 24 2 23.685 2 23.297V.703C2 .315 2.315 0 2.703 0h17.813zM8.796 1.406v.703c0 .388.303.704.675.704h4.277c.372 0 .674-.316.674-.704v-.703H8.797zm-5.39 0v21.188H15.5v-3.61c0-.388.315-.703.703-.703h3.61V1.406h-3.985v.703c0 1.163-.933 2.11-2.08 2.11H9.47c-1.147 0-2.08-.947-2.08-2.11v-.703H3.405zm15.412 18.282h-1.912v1.911l1.912-1.912zm-.917 2.906h1.912v-1.912L17.9 22.594zM6.407 15.047c-.18 0-.36-.069-.497-.206l-.892-.892c-.274-.274-.274-.72 0-.994.275-.275.72-.275.995 0l.407.407 1.424-1.352c.282-.267.727-.256.994.026.267.281.256.726-.026.994l-1.92 1.824c-.137.129-.31.193-.485.193zm1.921-9.422c.388 0 .703.315.703.703v2.813c0 .388-.315.703-.703.703H5.516c-.389 0-.704-.315-.704-.703V6.328c0-.388.315-.703.704-.703h2.812zm-.703 2.813V7.03H6.219v1.407h1.406zm.703 8.484c.388 0 .703.315.703.703v2.813c0 .388-.315.703-.703.703H5.516c-.389 0-.704-.315-.704-.703v-2.813c0-.388.315-.703.704-.703h2.812zm-.703 2.812v-1.406H6.219v1.406h1.406zm3.516-12.703c-.389 0-.704-.315-.704-.703 0-.388.315-.703.704-.703h6.562c.388 0 .703.315.703.703 0 .388-.315.703-.703.703h-6.562zm0 2.813c-.389 0-.704-.315-.704-.703 0-.389.315-.704.704-.704h5.109c.388 0 .703.315.703.704 0 .388-.315.703-.703.703h-5.11zm0 2.812c-.389 0-.704-.315-.704-.703 0-.388.315-.703.704-.703h6.562c.388 0 .703.315.703.703 0 .388-.315.703-.703.703h-6.562zm0 2.813c-.389 0-.704-.315-.704-.703 0-.389.315-.704.704-.704h5.109c.388 0 .703.315.703.704 0 .388-.315.703-.703.703h-5.11zm2.25 1.453c.388 0 .703.315.703.703 0 .388-.315.703-.703.703h-2.25c-.389 0-.704-.315-.704-.703 0-.388.315-.703.704-.703h2.25zm-.681 2.812c.388 0 .703.315.703.703 0 .389-.315.704-.703.704h-1.57c-.388 0-.703-.315-.703-.704 0-.388.315-.703.704-.703h1.569z"></path></svg>
</template>

<script>
export default {
    name: "icon-typeform",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.icon-typeform {

}

</style>
