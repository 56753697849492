


<template>
  <div>
    <div class="pb-2">


    <v-select
        v-model="details.type"
        :items="socialMediaTypes"
        :menu-props="{ maxHeight: '400' }"
        label="Social Media Website"
        hide-details
        deletable-chips
        no-data-text="No data available"
    ></v-select>
    </div>
    <div class="pt-5 pb-2">
      <v-text-field
          dense
          label="Display Text"
          placeholder="Follow Us"
          v-model="details.text"
          hide-details
      ></v-text-field>
    </div>
    <div class="pt-5 pb-2">
      <v-text-field
          dense
          label="Link"
          placeholder="https://"
          v-model="details.url"
          hide-details
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
    name: "editor-social",
    props: {
      details: {
        type: Object,
        required: true
      }
    },
    data: function(){
        return {
          socialMediaTypes: ["Instagram","Twitter","Facebook","Youtube","Linkedin","Other"]
        }
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.editor-cta {

}

</style>
