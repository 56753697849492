<template>
  <v-app id="app" class="">
<!--    <div class="absolute top-0 left-0 hidden">-->
<!--      <img class="" src="/img/portal.scantrust.com_.png" style="width:1480px;"/>-->
<!--    </div>-->

    <header-bar class="z-50" :selection="page" v-if="isLoaded && showHeader"/>
    <router-view class="page" v-if="showContent" :class="showRegistration?'blur':''"/>
    <div style="display:none" class="checklist">
      <v-menu transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="secondary"
              class="ma-2"
              v-bind="attrs"
              v-on="on"
          >
            Scroll Y Transition
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="n in 5"
              :key="n"
              link
          >
            <v-list-item-title v-text="'Item ' + n"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="checklist" v-if="isLoaded && !isPreview">
      <v-btn v-if="!$store.state.demo.checklist" elevation="2" large rounded class="black white--text" @click="$store.state.demo.checklist = true">
        <v-icon left>mdi-help-circle-outline</v-icon>
        <span class="d-flex align-center"><img class="epac-logo" src="https://epacflexibles.com/wp-content/themes/CC-EPAC/img/logo.png" /> Guided Tour</span>

      </v-btn>
    </div>
    <div class="checklist" v-if="isLoaded && !isPreview">
      <v-slide-y-transition>
        <v-card
            class="elevation-24 px-0"
            max-width="300px"
            v-if="$store.state.demo.checklist"
            color="#111"
            dark
        >
          <v-card-title class="black white--text justify-space-between align-center py-2"><span class="d-flex align-center"><img class="epac-logo" src="https://epacflexibles.com/wp-content/themes/CC-EPAC/img/logo.png" /> Guided Tour</span><v-btn class="px-0" @click="$store.state.demo.checklist = false" fab dark x-small plain><v-icon>mdi-close</v-icon></v-btn></v-card-title>
          <v-divider color="#555"></v-divider>
          <v-card-text class="py-0 px-0">
            <div class="ma-4">

              <v-progress-linear
                  :value="progress"
                  rounded
                  height="6"
                  color="#fe6232"
                  background-color="#444"
              ></v-progress-linear>
            </div>
            <v-divider color="#555"></v-divider>



            <div v-for="(task, index) in tasks" :key="'task'+index">
            <v-list-item class="px-4" @click="setTask(index)">
                <v-list-item-icon class="mr-4"><v-icon class="check-icon" :class="task.completed?'completed':''">{{ task.completed?'mdi-checkbox-marked-circle':'mdi-checkbox-blank-circle-outline' }}</v-icon></v-list-item-icon>
                <v-list-item-content class="link-content">
                  <v-list-item-title>{{ task.title  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ task.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider color="#555"></v-divider>
            </div>




          </v-card-text>

          <v-card-actions class="justify-space-between pa-4" >
            <v-btn small plain dark>Contact Sales</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="#fe6232" dark @click="beginTour">{{ beginLabel }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>
    </div>
    <div v-if="allowReg">
      <registration-panel :registration="showRegistration" @registered="onRegistered"></registration-panel>
    </div>
  </v-app>
</template>

<style>

:root {
  --primary-blue: #15bbf0;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.header-bar {
  z-index: 50;
}

.page {
  flex-grow: 1;
}

.checklist {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
  z-index: 23;

}

.checklist .v-list-item:hover .v-list-item__content {
  text-decoration: underline;
  cursor: pointer;

}

.relative {
  position: relative;
}

.random {
  position: absolute;
  width: 100px;
  height: 100px;
  left: -10px;
  top: 100px;
  background-color: red;
  z-index: 1000;

}

.epac-logo {
  width: 60px;
  margin-bottom: -6px;
  margin-right: 12px;
}

.v-icon.check-icon.completed {
  color: #fe6232;
}

.page.blur {
  filter: blur(10px);
}


</style>
<script>
import HeaderBar from "@/components/HeaderBar";
//import RegistrationDialog from "@/components/RegistrationDialog";
import RegistrationPanel from "@/components/RegistrationPanel";
export default {
  components: {RegistrationPanel,  HeaderBar},
  data: function(){
    return {
      step: 1,
      allowReg: false
    }
  },

  async mounted() {

    let previewMode = window.location.hash.indexOf("#/preview") > -1;



    if(!previewMode){
      this.allowReg = true;
      console.log("mounting session")
      let session = await this.$store.dispatch("loadSession");
      console.log("session:", session)

      if(session.reviewer && session.verified){
        console.log("reviewer verified", session.reviewer);
        let ts = this.$store.state.sessionId;
        if(ts > (Date.now() - (1 * 1 * 60 * 1000))){
          this.skipRegistration();
        }else{
          console.log("session is more than 1 hour old");
          return;
        }
      }else{
        console.log("unverified user");
        localStorage.setItem("sessionId", 0)
      }
    }else{
        console.log("preview")
    }

  },

  methods: {

    skipRegistration: function(){
      this.$store.state.authorized = true;
    },

    beginTour: function(){
      let nextTask = this.tasks.findIndex((task) => {
        return !task.completed;
      })

      if(nextTask < 0){
        this.setTask(0)
      }else{
        this.setTask(nextTask);
      }
    },

    onRegistered: function(){
      console.log("registered");
      this.$store.state.loaded = true;
      this.$store.state.authorized = true;
      this.$router.push("/landing-page?tab=editor");


      setTimeout(()=>{
        this.$store.state.demo.checklist = true;
      }, 300)
    },

    setTask: function(task){
      console.log("setTask", task);
      console.log(this.$route);
      if(task === 0){
        if(this.$route.path !== "/products"){
          console.log("not products");
          this.$store.dispatch("setTask", {task: 0, step: 1})
        }else{
          this.$store.dispatch("setTask", {task: 0, step: 2})
        }
      }

      if(task === 1){
        if(this.$route.path !== "/landing-page"){
          console.log("not products");
          this.$store.dispatch("setTask", {task: 1, step: 1})
        }else{
          if(this.$route.query.tab !== "editor"){
            this.$store.dispatch("setTask", {task: 1, step: 2})
          }else{
            this.$store.dispatch("setTask", {task: 1, step: 3})
          }
        }
      }

      if(task === 2){
        if(this.$route.path !== "/landing-page"){
          this.$router.push("/landing-page?tab=editor")
        }else{
          if(this.$route.query.tab !== "editor"){
            this.$router.push("/landing-page?tab=editor")
          }
        }
        this.$store.dispatch("setTask", {task: 2, step: 1})
      }
    },


  },
  computed: {
    isLoaded: function(){
      return this.$store.state.loaded;
    },

    isPreview: function(){
      return (this.$route.meta.module === "preview")
    },

    isTest: function(){
      return (this.$route.meta.module === "drag")
    },


    page: function(){
      return this.$route.meta.module
    },

    showHeader: function(){
      return !this.isPreview;
    },

    showContent: function(){

      if(this.isPreview){
        return true;
      }else{
        return this.isLoaded;
      }

    },

    showRegistration: function(){

      if(this.$route.name === "Preview"){
        return false;
      }

      if(!localStorage.getItem("reviewer")){
        return true;
      }

      return (this.$store.state.loaded && !this.$store.state.authorized);
    },

    completedSteps: function(){
      return this.tasks.reduce((total, task) => {
        if(task.completed){
          total++;
        }
        return total;
      }, 0)
    },

    beginLabel: function(){
      if(this.completedSteps){
        if(this.completedSteps === this.totalSteps){
          return "Restart Tour"
        }else{
          return "Continue Tour"
        }

      }else{
        return "Begin Tour"
      }
    },

    progress: function(){
      return (this.completedSteps / this.totalSteps) * 100;
    },

    totalSteps: function(){
      return this.tasks.length;
    },

    tasks: function(){
      return this.$store.state.demo.tasks;
    }
  }
}
</script>
